
export default function PackagesComp() {
    return (
        <section className="pricing-package-three">
            <div className="pricing-package-one-shape-1" />
            <div className="pricing-package-one-shape-2" />
            <div className="pricing-package-one-shape-3" />
            <div className="pricing-package-one-shape-4" />
            <div className="pricing-package-one-shape-5" />
            <div className="pricing-package-one-shape-6" />
            <div className="pricing-package-one-shape-7" />
            <div className="pricing-package-one-shape-8" />
            <div className="pricing-package-one-shape-9" />
            <div className="container">
                {/* section-title */}
                <div className="section-title text-center">
                    <i className="icon-main-icon" />
                    <span>Pricing Package</span>
                </div>
                {/* section-main-title */}
                <div className="section-main-title text-center">
                    <h2 className="mx-auto">We Provide
                        <span className="section-main-title-primery">Popular Pricing</span>
                        to Enjoy your Daily Life
                    </h2>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInRight animated" data-wow-duration="1500ms" data-wow-delay="00ms">
                        {/* pricing-package-three-box */}
                        <div className="pricing-package-three-box">
                            {/* box-title */}
                            <div className="box-title">
                                <h3>Basic</h3>
                                <div className="box-price text-shape">
                                    <p><span>$</span>28<span>/month</span>
                                    </p>
                                </div>
                            </div>
                            {/* box-icon */}
                            <div className="box-icon">
                                <i className="flaticon-network-cable" />
                                <h3>Fiber Optical</h3>
                            </div>
                            {/* box-item-list */}
                            <ul className="box-item-list">
                                <li><i className="icon-angle-double-right" />Internet with <span>100Mbps</span></li>
                                <li><i className="icon-angle-double-right" />Unlimited devices (6-8 Devices)</li>
                                <li><i className="icon-angle-double-right" />50+ HD Channels</li>
                                <li><i className="icon-angle-double-right" />Wi-Fi router &amp; prevention</li>
                                <li><i className="icon-angle-double-right" />Multiple users </li>
                            </ul>
                            {/* box-price */}
                            {/* btn-transparent */}
                            {/* <Link to={MAIN.packages} className="btn btn-transparent">Choose Package<i className="icon-1" /></Link> */}
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInRight animated" data-wow-duration="1500ms" data-wow-delay="150ms">
                        {/* pricing-package-three-box */}
                        <div className="pricing-package-three-box">
                            {/* box-title */}
                            <div className="box-title">
                                <h3>Standard</h3>
                                <div className="box-price text-shape">
                                    <p><span>$</span>97<span>/month</span></p>
                                </div>
                            </div>
                            {/* box-icon */}
                            <div className="box-icon">
                                <i className="flaticon-smart-tv-1" />
                                <h3>TV + Internet</h3>
                            </div>
                            {/* box-item-list */}
                            <ul className="box-item-list">
                                <li><i className="icon-angle-double-right" />Internet with <span>100Mbps</span></li>
                                <li><i className="icon-angle-double-right" />Unlimited devices (6-8 Devices)</li>
                                <li><i className="icon-angle-double-right" />100+ HD Channels</li>
                                <li><i className="icon-angle-double-right" />Wi-Fi router &amp; prevention</li>
                                <li><i className="icon-angle-double-right" />Multiple users </li>
                            </ul>
                            {/* box-price */}
                            {/* btn-transparent */}
                            {/* <Link to={MAIN.packages} className="btn btn-transparent">Choose Package<i className="icon-1" /></Link> */}
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInRight animated" data-wow-duration="1500ms" data-wow-delay="300ms">
                        {/* pricing-package-three-box */}
                        <div className="pricing-package-three-box">
                            {/* box-title */}
                            <div className="box-title">
                                <h3>Premier</h3>
                                <div className="box-price text-shape">
                                    <p><span>$</span>218<span>/month</span></p>
                                </div>
                            </div>
                            {/* box-icon */}
                            <div className="box-icon">
                                <i className="flaticon-internet-of-things" />
                                <h3>Int +Mobile +Tv</h3>
                            </div>
                            {/* box-item-list */}
                            <ul className="box-item-list">
                                <li><i className="icon-angle-double-right" />Internet with <span>100Mbps</span></li>
                                <li><i className="icon-angle-double-right" />Unlimited devices (6-8 Devices)</li>
                                <li><i className="icon-angle-double-right" />150+ HD Channels</li>
                                <li><i className="icon-angle-double-right" />Wi-Fi router &amp; prevention</li>
                                <li><i className="icon-angle-double-right" />Multiple users </li>
                            </ul>
                            {/* box-price */}
                            {/* btn-transparent */}
                            {/* <Link to={MAIN.packages} className="btn btn-transparent">Choose Package<i className="icon-1" /></Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
